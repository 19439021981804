import React, { useContext, useEffect, useState } from "react";
import FirebaseContext from "./Firebase/FirebaseContext";
import styled from "styled-components";
import { Controller } from "./Controller";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background: #008bd2;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  -webkit-box-shadow: 0px -7px 10px 10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -7px 10px 10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -7px 10px 10px rgba(0, 0, 0, 0.75);
`;

const Logo = styled.div`
  background: url("https://www.singknaben.ch/wp-content/uploads/2017/01/sk_logo_weiss.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 200px;
  @media (max-width: 600px) {
    width: 100px;
  }
`;

const Title = styled.div`
  font-family: "Arimo", sans-serif;
  font-size: 24px;
  color: white;
  margin-left: 10px;
`;

function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  let firebase = useContext(FirebaseContext);

  useEffect(() => {
    firebase?.isInitialized().then(() => {
      setFirebaseInitialized(true);
    });
  });

  return (
    <Wrapper>
      <Header>
        <Logo />
        <Title>Contact Tracing</Title>
      </Header>
      {firebaseInitialized && <Controller />}
    </Wrapper>
  );
}

export default App;
