import React, {useContext, useEffect, useState} from "react";
import FirebaseContext from "./Firebase/FirebaseContext";
import {CreateUser} from "./CreateUser";
import {LoggedIn} from "./LoggedIn";

export const Controller = () => {
  const [auth, setAuth] = useState(false);
  const [uid, setUid] = useState("");
  let firebase = useContext(FirebaseContext);
  useEffect(() => {
    setAuth(Boolean(firebase?.isLoggedIn()));
  }, [firebase]);
  useEffect(() => {
    if (auth && uid === "") {
      let response = firebase?.doGetUid();
      setUid(response ? response : "");
    }
  }, [auth, uid, firebase]);
  if (firebase === null) return <div />;

  return auth ? (
    <LoggedIn uid={uid} />
  ) : (
    <CreateUser refreshAuth={() => setAuth(Boolean(firebase?.isLoggedIn()))} />
  );
};
