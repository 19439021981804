import React, { useContext, useEffect, useState } from "react";
import FirebaseContext from "./Firebase/FirebaseContext";
import { Visitor } from "../models/Visitor";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: "Arimo", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  margin-bottom: 60px;
`;

const Button = styled.button`
  outline: none;
  border: none;
  background-color: #008bd2;
  color: white;
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  transition: 0.4s all;
  -webkit-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    transform: translateY(2px);
  }
`;

type Props = {
  uid: string;
};

export const LoggedIn = ({ uid }: Props) => {
  let firebase = useContext(FirebaseContext);
  const [visitor, setVisitor] = useState<Visitor>({
    uid: "",
    dbId: "",
    firstName: "",
    lastName: "",
    phone: "",
    present: false,
  });
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (firebase !== null && (visitor.uid === "" || refetch)) {
      setRefetch(false);
      firebase.doGetVisitorData(uid).then((result) => {
        let currentVisitor: Visitor[] = [];
        result?.forEach((doc: any) => {
          currentVisitor.push(doc.data());
          currentVisitor[currentVisitor.length - 1].dbId = doc.id;
        });
        if (currentVisitor.length > 0) setVisitor(currentVisitor[0]);
      });
    }
  }, [firebase, uid, visitor, setVisitor, refetch, setRefetch]);

  const handleButton = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (visitor.dbId !== undefined) {
      if (visitor.present) firebase?.doLeave(visitor.dbId);
      else firebase?.doReturn(visitor.dbId);
      setRefetch(true);
    }
  };

  return (
    <Wrapper>
      {visitor.uid !== "" && (
        <Container>
          <Title>Hallo {visitor.firstName}</Title>
          <Description>
            Falls du wieder {visitor.present ? "gehts" : "zurück kommst"},
            klicke bitte auf den Button.
          </Description>
          <Button onClick={(event1) => handleButton(event1)}>
            {visitor.present ? "Ich gehe wieder" : "Ich bin wieder zurück"}
          </Button>
        </Container>
      )}
    </Wrapper>
  );
};
