import * as React from "react";
import { useContext, useState } from "react";
import FirebaseContext from "./Firebase/FirebaseContext";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Container = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    width: 700px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow: hidden;

  & input {
    width: 100%;
    height: 100%;
    color: grey;
    padding: 20px 0 0 0;
    border: none;
    box-sizing: border-box;
    outline: none;
  }

  & label {
    position: absolute;
    bottom: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid black;
  }

  & label::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: -1px;
    border-bottom: 3px solid #008bd2;
    transform: translateX(-100%);
    transition: all 0.4s ease;
  }

  & label span {
    position: absolute;
    bottom: 5px;
    left: 0px;
    transition: all 0.4s ease;
  }

  & input:focus + .label-name .content-name,
  & input:valid + .label-name .content-name {
    transform: translateY(-150%);
    font-size: 12px;
    color: #008bd2;
  }

  & input:focus + .label-name::after,
  & input:valid + .label-name::after {
    transform: translateX(0);
  }
`;

const Button = styled.input`
  outline: none;
  border: none;
  background-color: #008bd2;
  color: white;
  font-family: "Arimo", sans-serif;
  font-size: 16px;
  transition: 0.4s all;
  -webkit-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 20px;

  &:hover {
    transform: translateY(2px);
  }
`;

type Props = {
  refreshAuth: () => void;
};

export const CreateUser = ({ refreshAuth }: Props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  let firebase = useContext(FirebaseContext);
  if (firebase === null) return <div />;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("submit");
    firebase
      ?.doSignIn()
      .then((response) => {
        firebase
          ?.doSaveVisitorData({
            uid: response.user?.uid,
            firstName,
            lastName,
            phone,
            present: true,
          })
          .then((response) => {
            firebase?.doReturn(response.id);
            refreshAuth();
          })
          .catch((error1) => console.error("VISITOR_INFO: ", error1));
      })
      .catch((error) => console.error("SIGN_IN: ", error));
  };

  return (
    <Wrapper>
      <Container>
        <Form autoComplete={"on"} onSubmit={(event) => handleSubmit(event)}>
          <InputContainer>
            <input
              type="text"
              id="fname"
              name="fname"
              autoComplete={"given-name"}
              value={firstName}
              onChange={(event1) => setFirstName(event1.target.value)}
              required
            />
            <label htmlFor="fname" className={"label-name"}>
              <span className={"content-name"}>Vorname</span>
            </label>
          </InputContainer>
          <InputContainer>
            <input
              type="text"
              id="lname"
              name="lname"
              autoComplete={"family-name"}
              value={lastName}
              onChange={(event1) => setLastName(event1.target.value)}
              required
            />
            <label htmlFor="lname" className={"label-name"}>
              <span className={"content-name"}>Nachname</span>
            </label>
          </InputContainer>
          <InputContainer>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              autoComplete={"tel"}
              value={phone}
              onChange={(event1) => setPhone(event1.target.value)}
              required
            />
            <label htmlFor="phone" className={"label-name"}>
              <span className={"content-name"}>Telefonnummer</span>
            </label>
          </InputContainer>
          <Button type="submit" />
        </Form>
      </Container>
    </Wrapper>
  );
};
