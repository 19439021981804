import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { Visitor } from "../../models/Visitor";

const firebaseConfig = {
  apiKey: "AIzaSyDV97N1iDAByJo8zle6NKt5GO2bJEge2H0",
  authDomain: "singknaben-contact-tracing.firebaseapp.com",
  databaseURL: "https://singknaben-contact-tracing.firebaseio.com",
  projectId: "singknaben-contact-tracing",
  storageBucket: "singknaben-contact-tracing.appspot.com",
  messagingSenderId: "20642498403",
  appId: "1:20642498403:web:b6b4547db8dfd107083556",
};

class Firebase {
  private auth: firebase.auth.Auth;
  private db: firebase.firestore.Firestore;
  private storage: firebase.storage.Storage;
  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  doSignIn = () => this.auth.signInAnonymously();

  doSignOut = () => this.auth.signOut();

  doGetUid = () => this.auth.currentUser?.uid;

  isInitialized = () =>
    new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });

  isLoggedIn = () => Boolean(this.auth.currentUser);

  doSaveVisitorData = (visitor: Visitor) =>
    this.db.collection("visitors").add(visitor);

  doGetVisitorData = (uid: string) =>
    this.db
      .collection("visitors")
      .where("uid", "==", uid)
      .get()
      .then((snapshot: any) => {
        if (snapshot.empty) {
          console.log("No matching documents.");
          return;
        }
        return snapshot;
      })
      .catch((err: any) => {
        console.error("GET_VISITOR_INFO", err);
      });

  doLeave = (id: string) => {
    this.db.collection("visitors").doc(id).update({ present: false });
    this.db
      .collection("movements")
      .add({ id, movement: "left", time: new Date() });
  };

  doReturn = (id: string) => {
    this.db.collection("visitors").doc(id).update({ present: true });
    this.db
      .collection("movements")
      .add({ id, movement: "returned", time: new Date() });
  };
}

export default Firebase;
